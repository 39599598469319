import { useState, useEffect } from 'react';
import { API, Auth, Storage } from 'aws-amplify';

import {
    Heading,
    Expander,
    Button,
    Link,
    Text,
    View,
    Collection,
    Rating,
    Loader,
    ExpanderItem,
    TextField,
    Image,
    Flex,
    Badge,
    Tabs,
    TabItem
} from '@aws-amplify/ui-react';

import { MdRefresh } from 'react-icons/md'; //https://react-icons.github.io/react-icons

import { useNavigate } from "react-router-dom";
import { AddProfile } from "./AddProfile";

export function Tasks({ updateProfiles, aProfile, updateAProfile, profiles, balance, updateBalance }) {
    const aProfileCreatedDate = new Date(aProfile.createdDate).getTime();;
    const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const navigate = useNavigate();
    const hostname = window.location.hostname;

    const [isMobile] = useState(/iphone|ipad/i.test(navigator.userAgent));

    const [isLoading, setLoading] = useState(true);

    const [tasks, setTasks] = useState([]);

    const [doneButtons, setDoneButtons] = useState([]);

    const [repostUrlHasError, setRepostUrlHasError] = useState(false);

    async function getTasks(forProfile) {
        if (forProfile.profileUrl === undefined) return;
        setLoading(true);
        const userUpdate = await Auth.currentAuthenticatedUser();
        const requestTasks = {
            headers: {
                Authorization: `${userUpdate.signInUserSession.idToken.jwtToken}`
            }
        };

        var data = [];

        try {
            data = await API.get("gcAPI", `/get-tasks/${userUpdate.username + '_' + encodeURIComponent(forProfile.profileUrl)}/new`, requestTasks);
        } catch (e) {
            console.log('retrying after 30 sec timeout...');
            try {
                data = await API.get("gcAPI", `/get-tasks/${userUpdate.username + '_' + encodeURIComponent(forProfile.profileUrl)}/new`, requestTasks);
            } catch (e) {
                console.log('something went wrong', e.message);
            };
        };

        //        console.log("tasks data", data);

        setTasks(await Promise.all(data.map(async (el) => {
            el.oldAvatarUrl = el.avatarUrl;
            if (el.SK.indexOf("TASK CONNECT") > -1) {
                //strip last "/" from SK if present
                var allAvas = [
                    "0be0ea02e75ea8424ddf",
                    "76ac73b6f9eecc945d8a",
                    "Bogota",
                    "CosmoBlue",
                    "ElephantCat",
                    "Etno",
                    "jiri-terc-66160273",
                    "jonaspetersenfromsisaro",
                    "jvelascoastete",
                    "ken-ratama-16a52718a",
                    "PechorinBloom",
                    "Spanglinga"
                ];
                let newSK = allAvas[Math.floor(Math.random() * allAvas.length)];
                el.avatarUrl = 'avatars/' + newSK + '.png';
            } else if (el.SK.indexOf("TASK POST") > -1) {
                //do nothing as it is either image or empty string and will be handled on frontend
            } else {
                try {
                    el.avatarUrl = await Storage.get(el.avatarUrl, {
                        level: "protected",
                        identityId: el.authorUser
                    });
                } catch (error) { el.avatarUrl = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'; }
            };
            return { ...el }
        })));

        //from tasks create array of false values matching the length of tasks
        await setDoneButtons(new Array(data.length).fill(false));

        setLoading(false);
    };

    async function setTaskStage(taskItem, stage, elementId) {
        var repostUrl = "";
        if (taskItem.requestType.indexOf("REPOST") > -1) {
            repostUrl = document.getElementById("repostUrl-" + elementId).value;
        };

        const user = await Auth.currentAuthenticatedUser();
        const requestSetTaskStage = {
            headers: {
                Authorization: `${user.signInUserSession.idToken.jwtToken}`
            },
            body: {
                taskItem: taskItem,
                newStage: stage,
            }
        };
        if (taskItem.requestType.indexOf("REPOST") > -1) {
            if (repostUrl.length > 0) {
                requestSetTaskStage.body.repostUrl = repostUrl;
            } else {
                if (stage !== "rejected") {
                    setRepostUrlHasError(true);
                    return;
                };
            }
        };

        //remove parent node by id = elementId & make no error on other possible reposts inputs left in the feed
        ((document.getElementById(elementId)).parentNode).remove();
        setRepostUrlHasError(false);

        try {
            const data = await API.post("gcAPI", `/update-task`, requestSetTaskStage);
            if (data.res === "success") {
                //show MORE TASKS if all done/skipped
            } else {
                console.log(data);
            };
        } catch (e) {
            console.log(e.message);
        };

        if (document.querySelectorAll('div.amplify-expander').length === 0) await getTasks(aProfile);
    };

    useEffect(() => {
        if ((Object.keys(aProfile).length === 0) &&
            (profiles.length > 0)) updateAProfile(profiles[0]);
        if ((tasks.length === 0) && (aProfile !== undefined)) getTasks(aProfile);
        // eslint-disable-next-line
    }, [profiles, updateAProfile, aProfile]);

    function tasksByType(inputTasks, type) {

        let tasks = inputTasks.filter(task => task.SK.indexOf(type) > -1);

        return <Collection
            direction='column'
            type='list'
            items={tasks}
            searchNoResultsFound={
                <Flex justifyContent="center">
                    <Text>
                        Nothing found, please try starting from <b>Grow</b> tab in <Link href='/settings/'>Settings</Link>.
                    </Text>
                </Flex>
            }
        >
            {(item, index) => (
                <View
                    key={index}
                    padding="0rem"
                >
                    <Expander
                        className='task-expander'
                        isCollapsible={true}
                        maxWidth="vw"
                        id={"task-" + index}
                    >
                        <ExpanderItem title={
                            <Flex
                                wrap="nowrap"
                                alignItems="center"
                                justifyContent="flex-start"
                                width="100%"
                            >
                                {item.avatarUrl.length > 0 ?
                                    <img src={item.avatarUrl} style={{ borderRadius: '50%', maxWidth: '50px', paddingTop: '0.5rem', paddingBottom: '0.5rem' }} alt={item.name} />
                                    :
                                    <Badge backgroundColor="hsl(200, 100%, 90%)">
                                        <Text style={{ fontSize: '30px' }}>

                                            {item.url ? item.url.replace('https://www.', '').replace('https://', '').replace('http://', '')[0].toUpperCase() : "P"}

                                        </Text>

                                    </Badge>
                                }
                                <Text isTruncated={true} style={{ width: '100%' }}>
                                    {item.SK.indexOf("TASK CONNECT") > -1 ?
                                        (item.stage === "new"
                                            ? <>CONNECT</>
                                            : <>CONFIRM CONNECT</>)
                                        : item.SK.indexOf("TASK POST") > -1 ?
                                            <>POST</>
                                            : <>{item.needsIntro ? " REPOST +" : ""}{item.needsComment ? " COMMENT +" : ""} LIKE [{item.like}] </>
                                    }
                                </Text>
                                {"pods" in item && item.pods.length > 0 ? <Badge>{item.pods}</Badge> : null}
                                <Flex
                                    wrap="nowrap"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    width="35%"
                                >
                                    <Text as="span"><b>Earn</b> {item.requestType.indexOf("PAID-") > -1 ? <>{item.quantity}0$<br />for 50</> : item.requestType === "PAID" ? <>5$<br />for 25</> : item.quantity}
                                        <Rating
                                            value={1}
                                            maxValue={1}
                                            fillColor="hsl(51, 100%, 50%)"
                                        />
                                    </Text>
                                </Flex>
                            </Flex>
                        }
                            value={'initial-' + index}
                            maxWidth="100%"
                        >
                            <Image
                                src={item.screenshot}
                                style={{ maxWidth: '100%' }}
                            />
                            <br />
                            {/*item.SK.indexOf("TASK POST") > -1 ?
                            <Text as="span"><b>{item.title}</b> <br />
                                {item.description}<br />
                                <Link
                                    href={item.url}
                                    target='_blank'
                                    rel="noreferrer">{item.source}</Link> [ {item.category} news ] <br /> <br />
                            </Text>
                            : null*/}
                            {item.SK.indexOf("TASK CONNECT") > -1 ?
                                <Text as="span">
                                    <b>{item.company}</b><br />
                                    {item.title} <br />
                                    {"countryCode" in item ? item.countryCode.toUpperCase() : ''}<br />
                                    <br />
                                </Text>
                                : null}

                            {item.SK.indexOf("TASK POST") > -1 ?
                                <Text as="span"><b>Step 1.</b> Copy suggested {item.category} news post text and link: <br /><br /><i><b>{item.title}</b><br /><br /> {item.postText}<br /><br /> {item.url}</i><br />
                                    {!(navigator.clipboard) ?
                                        <><br /><Link
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                try {
                                                    let textToCopy = item.title + '\n\n' + item.postText + '\n\n' + item.url;
                                                    console.log(textToCopy);
                                                    await navigator.clipboard.writeText(textToCopy);
                                                } catch (err) {
                                                }
                                            }}
                                            id={"copy-" + index}
                                            href='#'
                                            rel="noreferrer">Click TO COPY post text</Link></>
                                        : null}
                                </Text>
                                : <Text as="span"><b>Step 1.</b> Open {item.SK.indexOf("TASK CONNECT") > -1 ? 'profile' : 'post'} URL: <Link
                                    onClick={() => {
                                        setDoneButtons(doneButtons.map((el, i) => {
                                            if (i === index) return false;
                                            return el;
                                        }));
                                    }}
                                    href={isMobile ? item.postUrl.replace(/https:\/\/(.+?)\.linkedin\.com/, 'linkedin://') : item.postUrl}
                                    target='_blank'
                                    rel="noreferrer">OPEN</Link>
                                </Text>}
                            <br /><br />
                            {item.SK.indexOf("TASK POST") > -1 ?
                                <Text as="span"><b>Step 2.</b> Open LinkedIn and publish the post: <Link
                                    onClick={() => {
                                        setDoneButtons(doneButtons.map((el, i) => {
                                            if (i === index) return false;
                                            return el;
                                        }));
                                    }}
                                    href={isMobile ? 'linkedin:///feed/' : 'https://www.linkedin.com/feed/'}
                                    target='_blank'
                                    rel="noreferrer">OPEN LinkedIn</Link><br /><br />
                                    <i>Remember! To schedule the post for later - click the clock icon near the Post button on LinkedIn.</i>
                                </Text>
                                : <Text as="span">
                                    <b>Step 2.</b>
                                    {item.SK.indexOf("TASK CONNECT") > -1
                                        ? item.stage === 'new'
                                            ? <> Connect by clicking: <b>Connect</b></>
                                            : <> If you see <b>Pending</b>, please click: <b>Pending</b> & then <b>Withdraw</b> in the popup <Button type="submit" onClick={() => setTaskStage(item, "rejected", "task-" + index)} backgroundColor="red" color="white">WITHDRAWN</Button> </>
                                        : <> Deliver LIKE by clicking: Like as [{item.like}]</>}
                                </Text>}
                            {(item.needsComment && ((item.requestType === "FREE") || (item.requestType === "PAID"))) ? <Text as="span"><br /><br /><b>Step 3.</b> Deliver a COMMENT by typing: <br /><br /><i>{item.comment}</i><br /><br /><Link
                                onClick={async (e) => {
                                    e.preventDefault();
                                    try {
                                        await navigator.clipboard.writeText(item.comment);
                                    } catch (err) {
                                    }
                                }}
                                id={"copy-" + index}
                                href='#'
                                rel="noreferrer">Click TO COPY comment text</Link></Text> : ""}
                            {item.requestType.indexOf("REPOST") > -1 ?
                                <>
                                    <br /><br /><b>Step 3.</b> Deliver COMMENT by typing: <br /><br /><i>{item.comment}</i><br /><br /><Link
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            try {
                                                await navigator.clipboard.writeText(item.comment);
                                            } catch (err) {
                                            }
                                        }}
                                        id={"copy-" + index}
                                        href='#'
                                        rel="noreferrer">Click TO COPY comment text</Link><br />
                                    <br /><br /><b>Step 4.</b> Deliver REPOST by clicking "Repost", then "...with your thoughts" & typing: <i>{item.intro}</i><br />
                                    <br /><br /><b>Step 5.</b> Provide the repost Url: <TextField
                                        id={"repostUrl-task-" + index}
                                        hasError={repostUrlHasError}
                                        errorMessage="please fill in correct LinkedIn repost URL"
                                        placeholder="e.g. https://www.linkedin.com/posts/LongString ..."
                                        onChange={() => {
                                            if (document.getElementById("repostUrl-task-" + index).value === "") {
                                                setRepostUrlHasError(true);
                                            } else {
                                                setRepostUrlHasError(false);
                                            };
                                        }
                                        }
                                    />
                                    <br /><br /><b>Step 6.</b>
                                </> : null}
                            {(item.needsComment && ((item.requestType === "FREE") || (item.requestType === "PAID"))) ?
                                <><br /><br /><Text as="span"><b>Step 4.</b></Text></> :
                                item.requestType.indexOf("REPOST") > -1 ? null :
                                    <><br /><br /><Text as="span"><b>Step 3.</b> </Text></>}
                            Confirm {
                                item.SK.indexOf("TASK POST") > -1 ? 'posting' :
                                    item.SK.indexOf("TASK CONNECT") > -1 ? 'connection' : 'delivery'} by clicking&nbsp;
                            <Button
                                disabled={doneButtons[index]}
                                type="submit"
                                onClick={() => {
                                    //if #repostUrl-task-0 is not empty
                                    if (item.requestType === "FREE-REPOST") {
                                        if (document.getElementById("repostUrl-task-" + index).value === "") {
                                            setRepostUrlHasError(true);
                                            return;
                                        } else {
                                            setRepostUrlHasError(false);
                                        };
                                    };
                                    if (item.SK.indexOf("TASK CONNECT") > -1) {
                                        if (item.stage === 'new') {
                                            setTaskStage(item, "pending", "task-" + index);
                                        } else if (item.stage === 'pending') {
                                            setTaskStage(item, "completed", "task-" + index);
                                        }
                                    } if (item.SK.indexOf("TASK POST") > -1) {
                                        setTaskStage(item, "completed", "task-" + index);
                                    } else {
                                        setTaskStage(item, "attempted", "task-" + index);
                                    };
                                    updateBalance(balance + item.quantity);
                                }}
                                id={"task-" + index}
                            >
                                {item.SK.indexOf("TASK CONNECT") > -1
                                    ? item.stage === 'new'
                                        ? 'DONE'
                                        : 'CONNECTED'
                                    : 'DONE'}

                            </Button>
                            <br />
                            <br />
                            {item.SK.indexOf("TASK CONNECT") < 0 || item.stage === 'new' ?
                                <Flex
                                    wrap="nowrap"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <Button type="submit" onClick={() => setTaskStage(item, "rejected", "task-" + index)} backgroundColor="red" color="white">
                                        SKIP
                                    </Button>
                                </Flex>
                                : null}
                        </ExpanderItem>
                    </Expander>
                </View>
            )}
        </Collection>

    }

    return (
        <View align="center">
            {Object.keys(aProfile).length > 0 ?
                <>
                    {aProfile.validated ?
                        <View maxWidth="1000px" align="left">
                            <Heading level={3} fontWeight={'bold'} textAlign="center" color={"#414c5a"}>
                                Tasks
                            </Heading>
                            <View align="center">
                                <Text variation="secondary">
                                    <br />
                                    Get to 100% in daily tasks to maximize your LinkedIn growth.
                                </Text>
                                <br />
                                {aProfile.requestIPCountry && aProfile.requestIPCountry.trim() !== "" && <Badge>{aProfile.requestIPCountry}</Badge>}
                                {aProfile.location && aProfile.location.trim() !== "" && <Badge>{aProfile.location}</Badge>}
                                {aProfile.industry && aProfile.industry.trim() !== "" && <Badge>{aProfile.industry}</Badge>}
                                {hostname.indexOf("1st") > -1 ?
                                    <></>
                                    :
                                    <View align="center"><br />Check video guide <Link href="https://youtu.be/ygOvsL7jlcY" target="_blank">here</Link></View>
                                }
                                {aProfileCreatedDate > twentyFourHoursAgo ?
                                    <View align="center">
                                        <Text variation="secondary">
                                            <br />
                                            <b>Important!</b> We check every task, so please click DONE only if you have completed it.
                                            <br />For first 24 hours after profile creation we slightly limit the number of tasks you can complete.
                                        </Text>
                                    </View>
                                    : null
                                }
                            </View>
                            <br />
                            <br />
                            {isLoading ? <Loader variation="linear" /> :
                                <Tabs spacing="equal">
                                    <TabItem title={isMobile ? "Engage" : "Engage"}>
                                        <><br />
                                            <Flex
                                                wrap="nowrap"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                Day's&nbsp;goal: <Loader variation="linear" isDeterminate percentage={
                                                    //if balance less than 100 and greater than or equal to 0, then balance else if balance greater than 100 then 100, if negative or 0 then 0
                                                    (balance < 100 && balance >= 0) ? balance : (balance > 100) ? 100 : 0
                                                } />
                                                <Button onClick={() => { window.location.reload() }}><MdRefresh title='REFRESH' /></Button>
                                            </Flex>
                                            <br />
                                            {tasksByType(tasks, "CAMPAIGN")}</>
                                    </TabItem>
                                    <TabItem title={isMobile ? "Post" : "Post"}>
                                        <><br />
                                            <Flex
                                                wrap="nowrap"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                Day's&nbsp;goal: <Loader variation="linear" isDeterminate percentage={
                                                    //if aProfile.lastPostDate less then 16 hrs ago, then 100% else 0%
                                                    (Date.now() - new Date(aProfile.lastPostDate).getTime()) < 16 * 60 * 60 * 1000 ? 100 : 0
                                                } />
                                                <Button onClick={() => { window.location.reload() }}><MdRefresh title='REFRESH' /></Button>
                                            </Flex>
                                            <br />
                                            {tasksByType(tasks, "POST")}</>
                                    </TabItem>
                                    <TabItem title={isMobile ? "Grow" : "Grow"}>
                                        <><br />
                                            <Flex
                                                wrap="nowrap"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                Week's&nbsp;goal: <Loader variation="linear" isDeterminate percentage={aProfile.connectionsWeeklyLimitCounter} />
                                                <Button onClick={() => { window.location.reload() }}><MdRefresh title='REFRESH' /></Button>
                                            </Flex>
                                            <br />
                                            {tasksByType(tasks, "CONNECT")}</>

                                    </TabItem>
                                </Tabs>
                            }

                            {(tasks.length === 0 && !isLoading) ? (balance > 54) ? <View textAlign="center"><br /><br />Please check again in few hours.</View> : <View textAlign="center"><br /><br /><Button onClick={() => { window.location.reload() }}>GET MORE TASKS</Button></View> : <></>}
                        </View>
                        :
                        <AddProfile updateProfiles={updateProfiles} aProfile={aProfile} updateAProfile={updateAProfile} />
                    }
                </>
                :
                <View maxWidth="1000px" align="left">
                    <Heading level={3} fontWeight={'bold'} textAlign="center">
                        Tasks
                    </Heading>
                    <View align="center">
                        <Text variation="secondary">
                            <br />
                            Start by adding social media profile to start earning from it.
                            <br />
                            We won't get any access to your profile or show it publicly.
                        </Text>
                        <br />
                        <Button variation="primary" onClick={() => { navigate("/addProfile") }}>ADD PROFILE</Button>
                    </View>
                    <br />
                    <br />
                </View>}
        </View>
    )
}