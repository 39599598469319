import { Rating } from '@aws-amplify/ui-react';

export function Balance (props) {
    //console.log("Balance props", props);
    return (
        <>  
            {(("fiatBalance" in props) && (props.fiatBalance !== undefined)) ? props.fiatBalance+"$ " : ""}
            {props.balance}&nbsp;<Rating
            value={1}
            maxValue={1}
            fillColor="hsl(51, 100%, 50%)"
            />            
        </>
    )
}