import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';

import {
  Heading,
  Text,
  View,
  useAuthenticator,
  Loader,
  Collection
} from '@aws-amplify/ui-react';
const reportDate = '2023-06';

export function PayoutsReport({ profiles }) {

  const [isLoading, setLoading] = useState(true);
  const [marketingData, setMarketingData] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);

  async function getMarketingReport() {
    var userLocal = undefined;
    try {
      userLocal = await Auth.currentAuthenticatedUser();
    } catch (error) {
      userLocal = user;
    };
    //when just signed out both user & userLocal are undefined
    try {
      const requestReport = {
        headers: {
          Authorization: `${userLocal.signInUserSession.idToken.jwtToken}`
        },
        body: { "reportDate": reportDate, "profileUrl": profiles ? profiles[0].SK : "" }
      };
      const data = await API.post("gcAPI", `/dashboard-campaigns-report`, requestReport);
      console.log("data:", data);

      var returnResponse = data.Data;

      //some returnResponse array items have chainedCampaigns array of SK like this, example of returnResponse array item: {SK:"SK", chainedCampaigns:["SK1","SK2"]}
      //for each chainedCampaigns array SK item find in the returnResponse campaign with matching SK
      //add to that campaign a property "parentCampaign" with the value of the campaign SK that has that chainedCampaigns array

      for (var i = 0; i < returnResponse.length; i++) {
        if ("chainedCampaigns" in returnResponse[i]) {
          for (var j = 0; j < returnResponse[i].chainedCampaigns.length; j++) {
            for (var k = 0; k < returnResponse.length; k++) {
              if (returnResponse[k].SK === returnResponse[i].chainedCampaigns[j]) {
                if (!("parentCampaign" in returnResponse[k]))
                  returnResponse[k].parentCampaign = returnResponse[i].SK;
              };
            };
          };
        };
      };

      console.log("returnResponse:", returnResponse);

      await setMarketingData(returnResponse);
      setLoading(false);
    } catch (e) {
      console.log('problem calling api', e);
    };
  }

  function numberCompleted(tasks) {
    var completed = 0;
    tasks.forEach(task => {
      if (task.stage === 'completed') completed++;
    });
    return completed;
  };

  useEffect(() => {
    //call API to get marketing report data
    if (marketingData.length < 1) getMarketingReport();
    //eslint-disable-next-line
  }, []);

  function colorLine(item) {
    if (item.requestType.indexOf("PAID-") > -1) return "red";
    if (item.requestType==="PAID") return "orange";
    if ("parentCampaign" in item) return "blue";
    return "black";
  };
    
  function CampaignsReport() {
    var result = marketingData;
    console.log("result:", result);
    //result is an array of objects with RequestTime property as a string ISO date
    //sort by RequestTime so that the most recent is first

    result.sort((a, b) => {
      if (a.RequestTime < b.RequestTime) return 1;
      if (a.RequestTime > b.RequestTime) return -1;
      return 0;
    });

    console.log("result:", result);

    return (
      <View>
        <Collection
          direction='column'
          type='list'
          items={result}
        >
          {(item, index) => (
            <Text key={index} style={{color: colorLine(item)}}>
              {item.SK} {item.RequestTime} {item.stage} {item.requestType} {item.requestIPCountry} {numberCompleted(item.tasks)} / {item.tasks.length} needed: {item.quantity - numberCompleted(item.tasks)} {item.taskPossible} {item.campaignUpdateStage} {item.currentStarsBalance} {("pods" in item && item.pods.length>0) ? "pods:"+item.pods : ""} {item.parentCampaign}
            </Text>
          )}
        </Collection>
      </View>
    );
  }

  console.log("profiles[0].SK:", profiles[0].SK);//===tasks[0].uuid

  return (
    <View align="center">
      <Heading level={3} fontWeight={'bold'} textAlign="center">
        Payouts Report for last 30 days {/*reportDate*/}
      </Heading>
      <View align="center">
        <Text variation="secondary">
          See the results of all {profiles ? profiles[0].SK : ""}
        </Text>
      </View>
      <br />
      <br />
      {isLoading ? <Loader variation="linear" /> : <CampaignsReport />}
    </View>
  )
}